footer {
  background-color: #1E326D;
  color: white;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;

  .top {
    padding: 120px 0;

    @media (max-width: 768px) {
      padding: 40px 0;
    }

  }

  .triangle {
    width: 100%;
    position: relative;
    z-index: 1000;
    height: 500px;
    background-color: red;
  }

  .btm {
    .inner-wrap {
      display: flex;
      padding: 40px 0;
      font-size: 13px;
      color: #a6abb1;
      letter-spacing: 0;
      line-height: 1.35;
      border-top: 1px solid #a6abb1;

      @media (max-width: 465px) {
        flex-direction: column;
        flex-wrap: nowrap;
        text-align: center;
        padding: 12.5px 0;
      }

      .col {
        &:nth-child(1) {
          flex-grow: 1;
        }

        @media (max-width: 465px) {
          padding: 2.5px 0;
        }
      }

      a {
        color: #a6abb1;
        text-decoration: underline;
      }
    }
  }
}