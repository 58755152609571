header {
  background: #FAFEFE;
  font-family: $title-font-family;
  box-shadow: 0 0px 10px 0px rgba(0, 0, 0, .2);
  z-index: 100;
  position: relative;

  .top {
    padding: 20px 0;

    a.logo {
      img {
        max-height: 85px;
      }
    }
  }

}

.social-icons {
  a {
    img {
      width: 90%;
    }
  }
}


#mobile-burger-btn {
  cursor: pointer;
  display: none;
  height: 38px;
  margin: 0;
  text-align: center;
  transition: right 0.5s;
  width: 40px;
  z-index: 100;
  border-radius: 0;
  background-color: $blue;
  padding: 0rem 0.25rem 0.25rem 0.25rem;

  @media (max-width: 768px) {
    display: inline-block;
    position: fixed;
    right: 5%;
    top: 45px;
    z-index: 9999;
  }

  .fa-bars {
    color: white;
    transition: right 0.5s;
  }

  .fa-times {
    color: white;
  }


  i {
    font-size: 28px;
    position: relative;
    top: 5px;

    &:nth-child(2) {
      display: none;
    }
  }

  &.menu-open {
    background-color: $blue;



    i {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        display: inline-block;
      }
    }
  }
}

.subMenu {

  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.28);
  right: -87px;
  top: 3rem;
  width: 240px;
  border-radius: 10px;

  background: #26629B;

  &:before {
    position: absolute;
    content: " ";
    display: inline-block;
    width: 16px;
    height: 16px;
    left: calc(50% - 11.5px);
    top: -8px;
    z-index: 100;
    background: #26629B;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.28);
    transform: rotate(45deg);
  }

  &:after {
    content: " ";
    position: absolute;
    width: 50px;
    height: 25px;
    z-index: 150;
    top: 0;
    left: calc(50% - 25px);
    background: #26629B;
  }

  h4 {
    a {
      font-size: 1.1rem;

      @media (max-width: 1400px) {
        font-size: 1.3rem;
      }

      @media (max-width: 1250px) {
        font-size: 1.5rem;
      }
    }
  }

  ul {
    margin-top: 10px;

    li {
      padding: 0.7rem 1.2rem;
      font-family: $standard-font-family;

      &.viewall {
        display: block;
        text-decoration: underline;
      }

      &:nth-child(1) {
        border-top: 0;
      }

      &:last-child {
        background: #1E326D;
        border-radius: 0 0 10px 10px;
      }

      a {
        font-size: 1rem;
        color: #ffffff;

        @media (max-width: 1400px) {
          font-size: 1.1rem;
        }

        @media (max-width: 1250px) {
          font-size: 1.2rem;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
