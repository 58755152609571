// ==========================================================================
// Global Imports
// ==========================================================================
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "vendors/index";
@import "base/index";
@import "layout/index";
@import "pages/index";
@import "components/index";