section.regular {
  position: relative;
}

.inner-wrap {
  max-width: $container + px;
  width: 82%;
  margin: auto;
}

.page-mid {
  .inner-wrap {
    padding: 50px 0;
  }
}

.blue-box {
  height: 400px;
  width: 75%;
  background-color: #ecfcfc;
  position: absolute;
  right: 30px;
  top: -170px;
  z-index: 1;

  @media (max-width: 960px) {
    display: none;
  }
}

.block-image {
  position: relative;
  z-index: 2;
}

.team-box {
  background: #FFFFFF;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.17);

  .img-wrap {
    width: 100%;


  }

  .txt-wrap {
    padding: 30px 20px;
  }
}

.content {
  h2 {
    @apply font-bold;
    color: #91CF72;
    margin-bottom: 1rem;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 1.25px;

    @media (max-width: 1300px) {
      font-size: 1.5rem;
      line-height: 3rem;
    }

    @media (max-width: 960px) {
      font-size: 2rem;
    }

    @media (max-width: 768px) {
      font-size: 2.2rem;
      line-height: 4rem;
    }

    @media (max-width: 640px) {
      font-size: 1.5rem;
    }

  }

  p {
    padding-bottom: 1rem;
  }
}




// pagination

ul.pagination {
  display: block;
  margin: 10px -5px 0;
  font-weight: bold;

  li {
    display: inline-block;
    margin: 2.5px 5px 2.5px 0px;

    a {
      display: block;

      &.secondary-btn {
        padding: 6px 16px;
        font-size: 1rem;
        background-color: $lightblue;
        color: #4a4a4a;

        &:hover {
          text-decoration: underline;
        }

        @media (max-width: 1300px) {
          font-size: 1.3rem;
        }

        @media (max-width: 960px) {
          font-size: 1.8rem;
        }

        @media (max-width: 768px) {
          font-size: 2.6rem;
        }

        @media (max-width: 640px) {
          font-size: 2rem;
        }
      }
    }

    &.active {
      a {
        &.secondary-btn {
          background-color: $blue;
          color: white;
        }
      }
    }
  }
}