.slideshow {
    position: relative;

    &::after {
        content: "";
        right: 0;
        bottom: -1px;
        width: 100%;
        position: absolute;
        background-image: url('/assets/img/curved-block.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: bottom center;
        z-index: 10;
        height: 23%;

        @media (max-width: 960px) {
            display: none;
        }
    }

    .slide {
        height: 43rem;
        max-height: 680px;
        padding-bottom: 6rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex !important;
        align-items: center;
        line-height: 1.25;

        &:after {
            content: " ";
            background-image: linear-gradient(180deg, #1e5384 1%, rgba(0, 0, 0, 0.02) 99%);
            opacity: 10%;
            width: 102%;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;

        }

        .inner-wrap {
            align-items: center;
            display: flex;

            .txt-wrap {
                width: 50%;
                text-align: left;
                margin: 0;
                position: relative;
                z-index: 1000;
                display: flex;
                align-items: center;

                @media (max-width: 1026px) {
                    width: 100%;
                }


            }
        }
    }
}

.slide-controls {
  position: absolute;
  left: 0;
  bottom: 11rem;
  width: 100%;
  height: 37px;
  z-index: 1000;
  overflow: hidden;
  @media (max-width: 960px) {
    bottom: 6rem;
  }
  @media (max-width: 800px) {
    display: none;
  }
  .inner-wrap {
    text-align: left;
    .btn-wrap {
      height: 37px;
      display: inline-block;
      cursor: pointer;

      .paging {
        display: inline-block;
        height: 37px;
        position: relative;
        vertical-align: top;
        padding: 0 ;
        ul.slick-dots {
          position: static;
          bottom: 0;
          overflow: hidden;
          font-size: 1rem;
          height: inherit;
          @media (max-width: 940px) {
            font-size: 10px;
          }
          li {
            padding: 0;
            margin: 0 .3rem;
            height: 1rem;
            width: 1rem;
            display: inline-block;
            position: relative;
            &:active {
              top: 1px;
            }
            @media (max-width: 940px) {
              margin: 0 .5rem;
            }
            button {
              background: none;
              height: 1.1rem;
              width: 1.1rem;
              border-radius: 50%;
              overflow: hidden;
              padding: 0;
              border: .15rem #1e5384 solid;
              margin: 0;
              &:before {
                display: none;
              }
              @media (max-width: 940px) {
                height: 12px;
                width: 12px;
                border: 2px #1e5384 solid;
              }
            }
            &.slick-active {
              button {
                background: #fff;
              }
            }
          }
        }
      }
      .prev-btn,
      .nxt-btn {
        width: 2rem;
        height: 2rem;
        display: inline-block;
        color: #1e5384;
        line-height: 1.9rem;
        text-align: center;
        position: relative;
        border-radius: 70%;
        border: 2px #1e5384 solid;
        &:active {
          top: 1px;
        }
        @media (max-width: 940px) {
          width: 20px;
          height: 20px;
          line-height: 18px;
        }
        @media (max-width: 940px) {
          width: 20px;
          height: 20px;
          line-height: 18px;
        }
      }
    }
  }
}
