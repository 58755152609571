.map {
  height: 400px;
}

.mapContent {
  color: #1e326d;

  h3 {
  @extend .large-font
  }

  p {
    margin: 5px 0;
  }

}
