$title-font-family: proxima-nova;
$standard-font-family: proxima-nova;

$title-font-book: 400;
$title-font-medium: 500;
$title-font-heavy: 700;

$standard-font-regular: 400;
$standard-font-medium: 500;
$standard-font-semibold: 600;
$standard-font-bold: 700;

$container: 1200;
$container_small: 1200;

$yellow: #ffe700;
$gold: $yellow;
$red: #b6051a;
$blue: #20609b;
$lightblue: #F5FDFD;

$imgpath: "../../../../assets/img/";

.extra-large-font {
  @apply font-bold;
  color: white;
  font-size: 4rem;
  line-height: 4.5rem;

  @media (max-width: 1300px) {
    font-size: 4.5rem;
    line-height: 5rem;
  }

  @media (max-width: 960px) {
    font-size: 6rem;
    line-height: 7rem;
  }

  @media (max-width: 768px) {
    font-size: 7.7rem;
    line-height: 8.5rem;
  }

  @media (max-width: 640px) {
    font-size: 6rem;
    line-height: 7rem;
  }
}

.semi-large-font {
  @apply font-bold;
  font-size: 3rem;

  @media (max-width: 1300px) {
    font-size: 3.75rem;
    line-height: 4.25rem;
  }

  @media (max-width: 960px) {
    font-size: 4.5rem;
    line-height: 5.5rem;
  }

  @media (max-width: 768px) {
    font-size: 5.5rem;
    line-height: 6.5rem;
  }

  @media (max-width: 640px) {
    font-size: 2.75rem;
    line-height: 3.5rem;
  }
}

.large-font {
  @apply font-bold;
  color: #1e326d;
  font-size: 1.75rem;
  line-height: 2.33rem;

  @media (max-width: 1300px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  @media (max-width: 960px) {
    font-size: 3rem;
    line-height: 4.2rem;
  }

  @media (max-width: 768px) {
    font-size: 3.5rem;
    line-height: 5rem;
  }

  @media (max-width: 640px) {
    font-size: 3rem;
    line-height: 4rem;
  }
}

.medium-font {
  @apply font-bold;
  font-size: 1.5rem;
  line-height: 2.3rem;

  @media (max-width: 1300px) {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  @media (max-width: 960px) {
    font-size: 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 3.5rem;
    line-height: 4rem;
  }

  @media (max-width: 640px) {
    font-size: 3rem;
    line-height: 4rem;
  }
}

.subtitle-font {
  @apply font-bold;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1.25px;

  @media (max-width: 1300px) {
    font-size: 1.5rem;
    line-height: 3rem;
  }

  @media (max-width: 960px) {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 2.2rem;
    line-height: 4rem;
  }

  @media (max-width: 640px) {
    font-size: 1.5rem;
  }
}

ul,
li,
a,
p,
.button,
.form-item {
  font-size: 1rem;

  @media (max-width: 1300px) {
    font-size: 1.3rem;
  }

  @media (max-width: 960px) {
    font-size: 1.8rem;
  }

  @media (max-width: 768px) {
    font-size: 2.6rem;
  }

  @media (max-width: 640px) {
    font-size: 1.75rem;
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
}

.bio {
  p {
    margin-top: 1rem;
  }
}
