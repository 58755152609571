.video-wrapper {
    position: relative;
    top: -35%;
    z-index: 30;

    @media (max-width: 960px) {
        top: 0;
        margin-bottom: 20px;
    }

    &:after {
        z-index: 20;
        position: absolute;
        right: -1rem;
        bottom: -3rem;
        width: 20rem;
        height: 7rem;
        background-color: #ECFCFC;
        display: inline-block;
        content: " ";

        @media (max-width: 960px) {
            display: none;
        }
    }
}

.video-responsive {
    z-index: 30;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.controls {
    display: flex;
    flex-direction: row;
    position: relative;
    right: 0;

    #prev-btn,
    #nxt-btn,
    #prev-btn2,
    #nxt-btn2 {
        cursor: pointer;


        &:active {
            margin-top: 1px;
        }
    }
}

.michael{
  background: red;
}

.blue-map-background {
    background-image: url('/assets/img/blue-map-background.png');
    margin-top: 5rem;
    padding-bottom: 21rem;

    @media (max-width: 640px) {
        padding-bottom: 0;
    }
}

.sub-banner {
    position: relative;
    top: -22rem;
    
    @media (max-width: 640px) {
        display: none;
    }
}



.text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 5%;
    height: 100%;
    display: flex;
    align-items: center;



}

.featured-product-text {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 960px) {
        width: 80%;
    }

    p {
        color: white;
    }


}

.product-play {
    background-color: #26629B;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    position: absolute;
    top: 41.5%;
    right: -50px;

    .play-button {
        width: 75%;
        right: -12px;
        top: 12px;
        position: relative;
    }

    @media (max-width: 960px) {
        top: -50px;
        left: calc(50% - 50px);
    }
}


.case-study {
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.17);
    border-bottom: 40px solid #F5FDFD;
    border-top: 5px solid #91CF72;

    .txt-wrap {
        padding: 30px;

        img {
            margin: 1rem 0;
            max-height: 70px;
        }

        p {

            color: #2C2020;
            line-height: 26px;
        }

        .client-name {
            color: #BFBFBF;


        }
    }

}

#case-studies {
    .slick-track {
        padding: 1rem 0;
    }
}


///////////////////////////// tabs




.r-tabs .r-tabs-nav {
    margin: 0;
    padding: 0;
    display: flex;
}

.r-tabs-anchor {
    color: #989697;
    letter-spacing: .01rem;
    font-weight: 800;
    font-size: .9rem;
    text-transform: uppercase;

    @media (max-width: 1300px) {
        font-size: 1.3rem;
    }

    @media (max-width: 960px) {
        font-size: 1.8rem;
    }

    @media (max-width: 768px) {
        font-size: 2.6rem;
    }

    @media (max-width: 640px) {
        font-size: 1.75rem;
    }

    @media (max-width: 500px) {
        font-size: 12px;
    }
}

.r-tabs .r-tabs-tab {
    display: inline-block;
    margin: 0;
    list-style: none;
    flex-grow: 1;
    text-align: center;
}

.r-tabs .r-tabs-panel {
    padding: 15px;
    display: none;
}

.r-tabs .r-tabs-accordion-title {
    display: none;
}

.r-tabs-state-active {
    background-color: white;
}

.r-tabs .r-tabs-panel.r-tabs-state-active {
    display: block;
}

@media only screen and (max-width: 960px) {
    .r-tabs .r-tabs-nav {
        display: none;
    }

    .r-tabs {
        background-color: white;

        &:last-child {
            border-bottom: 0;
        }

        .r-tabs-accordion-title {
            color: #1E326D;
            display: block;
            padding: 30px 0;
            text-transform: uppercase;
            font-size: 1em;
            font-weight: 700;
            letter-spacing: 1.25px;
            border-bottom: 1px solid #ECFCFC;

            @media (max-width: 500px) {
                font-size: 12px;
            }

        }

        .r-tabs-state-active {
            background-color: white;
        }
    }
}

.logo-division {
    position: relative;

}

.logo-division:hover .logo-image {
    opacity: 0.3;

}

.logo-division:hover {
    opacity: 0.85;
    background-color: #10365B;
    transition: .5s ease;
}

.logo-division:hover .logo-overlay {
    opacity: 1;

}

.logo-image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    backface-visibility: hidden;

}

.logo-overlay {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;

    .txt-wrap {
        color: white;
    }
}
