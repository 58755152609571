html {
  box-sizing: border-box;
  font-size: 1vw;

  @media(min-width: 1600px) {
    font-size: 16px;
  }

  @media (max-width: 960px) {}

  @media (max-width: 768px) {}

  @media (max-width: 640px) {
    font-size: 2vw;
  }
}

body {
  font-family: $standard-font-family;
  font-style: normal;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  margin: 0;
  background-color: #ffffff;
  color: #a1a1a1;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  cursor: pointer;
}

strong {
  font-weight: 700;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.page-wrap {
  position: relative;
  overflow: hidden;

  .page-inner-wrap {
    margin: 0 auto;
    position: relative;
    right: 0;
    z-index: 100;
    transition: right 0.3s ease;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    background: #ffffff;

    &.push {
      right: 354px;
    }
  }
}

.center {
  text-align: center;
}

.general {
  p {
    margin-bottom: 1.5em;
  }
}