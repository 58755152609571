#mobile-nav-items {
    display: block;
    position: fixed;
    top: 0;
    right: -354px;

    @media (max-width: 1040px) {
        right: 0;
    }

    max-width: 354px;
    width: 100vw;
    bottom: 0;
    height: 100%;
    z-index: 50;
    text-align: left;
    background: $blue;
    padding: 0;

    .top {
        padding: 30px;

        img {
            height: 70px;
        }
    }

    ul {
        padding: 0 30px;
        margin: 0;

        li {
            font-style: normal;
            display: block;
            text-align: left;
            border-bottom: 1px solid rgba(#979797, 0.2);
            line-height: 1.5;
            list-style: none;
            font-size: 1rem;
            box-sizing: border-box;
            text-decoration: inherit;
            cursor: pointer;
            font-weight: 500;
            margin-top: 0;

            @media (max-width: 500px) {
                font-size: 14px !important;
                font-weight: 600;
            }

            &:last-child {
                border: 0;
            }

            a,
            span {
                color: white;
                display: block;
                line-height: 1.25em;
                padding: 14px 0;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }

                i {
                    display: inline-block;
                    margin-left: 10px;
                    position: relative;
                    top: -3px;
                }
            }

            ul.second {
                display: none;
                margin: 5px 0 25px;
                padding: 0;

                li {
                    border-bottom: 0;
                    display: flex;
                    padding: 0;
                    text-transform: none;
                    margin-bottom: 10px;

                    span {
                        padding: 0;
                        margin-right: 8px;
                    }

                    a {
                        padding: 0;
                        font-size: 13px;
                        display: inline-block;
                    }
                }
            }

            ul.open {
                display: block;
            }

            &.active {
                color: #76a958;

                span {
                    color: #76a958;
                }

                ul.second {
                    display: block;

                    li {
                        a {
                            color: #fff;

                            &.active {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                >a {
                    color: #76a958;
                }
            }
        }
    }
}